import Image from 'next/future/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import cn from 'classnames';
import { useState } from 'react';
import Link from 'next/link';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { useSwiperSlider } from '@/components/commons/hooks';
import { getPageUrl } from '@/components/collections/helpers';
import { Content } from './types';
import { generateContent } from './helpers';
import Main from './Main';
import settings from '@/settings';

const Template = ({
  title,
  description,
  cta,
  banner,
  theme = 'light',
}: Content) => (
  <>
    {cta.link && (
      <Link href={getPageUrl(cta.link)} prefetch>
        <a>
          <div
            className={cn('relative flex h-full lg:px-20', {
              'bg-teal-500 text-gray-700': theme === 'light',
              'bg-gray-700 text-white': theme === 'dark',
            })}
            // eslint-disable-next-line react/forbid-dom-props
            style={{
              background:
                theme === 'light'
                  ? 'linear-gradient(90deg, rgba(146,247,250,1) 0%, rgba(146,247,250,1) 60%, rgba(146,250,213,1) 100%)'
                  : '',
            }}
          >
            <div className="reebelo-container flex w-full items-center lg:justify-center">
              <div className="z-10 max-w-[140px] text-sm xxs:max-w-[200px] xs:max-w-[250px] sm:max-w-[400px] md:max-w-[500px] lg:max-w-[400px]">
                <h1 className="text-xl font-bold sm:text-2xl">{title}</h1>
                <p className="mt-3 hidden lg:block">{description.desktop}</p>
                <p className="mt-3 lg:hidden">{description.mobile}</p>
                <div
                  className={cn(
                    'mt-5 inline-block rounded-full px-5 py-3 text-xs font-bold',
                    {
                      '!bg-gray-700 text-white': theme === 'light',
                      '!bg-teal-500 text-gray-700': theme === 'dark',
                    },
                  )}
                >
                  {cta.label}
                </div>
              </div>
              <div className="hidden h-full lg:block">
                <Image
                  src={banner.desktop}
                  alt="Desktop Hero"
                  className="h-full w-auto object-contain"
                  quality={100}
                  height={600}
                  width={800}
                />
              </div>
              <div className="absolute right-[-75px] h-full xxs:right-0 lg:hidden">
                <Image
                  src={banner.mobile}
                  alt="Mobile Hero"
                  className="h-full w-auto object-contain"
                  quality={100}
                  height={600}
                  width={800}
                />
              </div>
            </div>
          </div>
        </a>
      </Link>
    )}
  </>
);

interface SliderArrowsProps {
  prevRef: React.MutableRefObject<HTMLDivElement>;
  nextRef: React.MutableRefObject<HTMLDivElement>;
  theme: 'dark' | 'light';
  index: number;
}

const Arrows = ({ prevRef, nextRef, theme, index }: SliderArrowsProps) => {
  const containerCss =
    'group absolute z-50 h-full w-0 cursor-pointer lg:w-20 hidden';
  const innerCss =
    'h-4 w-4 transition-all duration-200 ease-in-out group-hover:h-5 group-hover:w-5';

  const generateArrowsColor = () => {
    if (index === 0) return 'white';
    if (theme === 'light') return '#1F2323';

    return 'white';
  };

  return (
    <div className="hidden lg:block">
      <div ref={prevRef} className={cn('left-0', containerCss)}>
        <div className="flex h-full items-center justify-center">
          <div className="rounded-full bg-transparent p-3 backdrop-blur-xl">
            <div className={innerCss}>
              <svg viewBox="0 0 100 100">
                <path
                  d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
                  transform="translate(0, 0) rotate(0)"
                  fill={generateArrowsColor()}
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div ref={nextRef} className={cn('right-0', containerCss)}>
        <div className="flex h-full items-center justify-center">
          <div className="rounded-full bg-transparent p-3 backdrop-blur-xl">
            <div className={innerCss}>
              <svg viewBox="0 0 100 100">
                <path
                  d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
                  transform="translate(100, 100) rotate(180)"
                  fill={generateArrowsColor()}
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Hero = () => {
  const { setSwiper, prevRef, nextRef } = useSwiperSlider();
  const [currentTheme, setCurrentTheme] = useState<'light' | 'dark'>('light');
  const [currentIndex, setCurrentIndex] = useState(0);

  const content: Content[] = generateContent(settings.store);

  const modules = [Navigation, Pagination, Autoplay];

  const handleNavigationArrows = (swiperObj: SwiperCore) => {
    if (prevRef.current !== undefined) {
      prevRef.current.classList.remove('hidden');
      nextRef.current.classList.remove('hidden');
      if (swiperObj.isBeginning) prevRef.current.classList.add('hidden');

      if (swiperObj.isEnd) nextRef.current.classList.add('hidden');
    }
  };

  return (
    <div className="relative">
      <Arrows
        prevRef={prevRef}
        nextRef={nextRef}
        theme={currentTheme}
        index={currentIndex}
      />
      <Swiper
        modules={modules}
        lazyPreloadPrevNext={1}
        onSlideChange={(swiper) => {
          setCurrentIndex(swiper.activeIndex);
          setCurrentTheme(content?.[swiper.activeIndex - 1]?.theme ?? 'light');
        }}
        onSwiper={(swiper) => setSwiper(swiper)}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
        pagination={{ clickable: true }}
        speed={500}
        onInit={(swiper) => handleNavigationArrows(swiper)}
        onResize={(swiper) => handleNavigationArrows(swiper)}
        onSlideChangeTransitionEnd={(swiper) => handleNavigationArrows(swiper)}
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className={cn('hero-swiper h-[280px]', {
          light: currentTheme === 'light',
          dark: currentTheme === 'dark',
        })}
      >
        <SwiperSlide>
          <Main />
        </SwiperSlide>
        {content.map((item) => (
          <SwiperSlide>
            <Template {...item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Hero;
