import Image from 'next/future/image';
import useSWR from 'swr';
import React, { useMemo, useState } from 'react';
import Loading from '@/components/Loading/Loading';
import vendors from '@/settings/vendors';
import Slider from '../../slider/Slider';

import { VendorSearchResultsOnlyOffers } from '@/lib/collections/vendor/vendor-search-helpers';
import { VendorKey } from '@/settings/vendors/types';

import Navigation from './Navigation';
import { vendorsBreakpoints } from '@/lib/homepage/constants';
import VisitStore from './VisitStore';
import Card from './Card';
import { getVendorImage } from '@/lib/homepage/helpers';

const Placeholder = () => (
  <React.Fragment>
    {[1, 2, 3, 4].map((i) => (
      <div
        key={i}
        className="flex h-full w-full items-center justify-center rounded-md bg-white"
      >
        <Loading />
      </div>
    ))}
  </React.Fragment>
);

function Vendors() {
  const certifiedVendors = Object.keys(vendors) as (keyof typeof vendors)[];
  const [selectedVendor, setSelectedVendor] = useState(certifiedVendors[0]);

  const vendorDetails = useMemo(
    () => vendors[selectedVendor as VendorKey],
    [vendors, selectedVendor],
  );

  const categories = useMemo(
    () => vendorDetails.search.filters?.categories,
    [vendorDetails],
  );
  const brand = useMemo(
    () => vendorDetails.search.filters?.brand ?? '',
    [vendorDetails],
  );

  const { data } = useSWR<VendorSearchResultsOnlyOffers>(
    () =>
      `/api/collections/vendor/vendor-search-results?size=10&handle=${selectedVendor}&categories${categories}&brand=${brand}`,
  );

  const offers = data?.offers ?? [];

  const usCertifiedVendors = certifiedVendors.filter((v) =>
    vendors[v].stores.includes('reebelo-us'),
  );

  return (
    <React.Fragment>
      <div className="flex items-center justify-between">
        <h2 className="flex items-center text-lg font-semibold md:text-xl">
          Official Stores
        </h2>
        <VisitStore
          vendor={selectedVendor}
          brand={brand}
          className="lg:hidden"
        />
      </div>
      <Navigation
        vendor={selectedVendor}
        setVendor={setSelectedVendor}
        brand={brand}
        title={vendorDetails.title}
        usVendors={usCertifiedVendors}
      />
      <div className="mt-5 grid w-full grid-cols-1 gap-2 lg:grid-cols-2">
        <div className="h-[300px] w-full lg:order-1 lg:min-h-[550px]">
          <Image
            src={getVendorImage(brand.toLowerCase())}
            className="h-full w-full rounded-md object-cover"
            alt={brand}
            loading="eager"
            quality={100}
          />
        </div>
        {/* Desktop */}
        <div className="hidden lg:grid lg:grid-cols-2 lg:grid-rows-2 lg:gap-2">
          {offers?.length ? (
            offers
              .slice(0, 4)
              .map((p) => <Card product={p} key={p.productId} />)
          ) : (
            <Placeholder />
          )}
        </div>
        {/* Mobile */}
        <div className="relative rounded-b-2xl lg:hidden">
          <div>
            <Slider breakpoints={vendorsBreakpoints} speed={300}>
              {offers?.length
                ? offers?.map((p) => <Card product={p} key={p.productId} />)
                : [1, 2, 3, 4].map((i) => (
                    <div
                      key={i}
                      className="flex h-full min-h-[262px] w-full items-center justify-center rounded-md bg-white"
                    >
                      <Loading />
                    </div>
                  ))}
            </Slider>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Vendors;
