import Image from 'next/future/image';
import { Dialog } from '@headlessui/react';
import { ReebeloStoreT, REEBELO_DOMAINS } from '@lambda/reebelo';
import { useRouter } from 'next/router';
import imgAU from '@/public/icons/country-flags/AU.svg';
import imgNZ from '@/public/icons/country-flags/NZ.svg';
import imgSG from '@/public/icons/country-flags/SG.svg';
import imgUS from '@/public/icons/country-flags/US.svg';
import imgCA from '@/public/icons/country-flags/CA.svg';
import i18n from '@/settings/i18n';
import settings from '@/settings';

const t = i18n.init();
const ALL_STORES = [
  {
    img: imgUS,
    title: 'United States',
    store: 'reebelo-us',
    lang: 'en',
  },
  {
    img: imgAU,
    title: 'Australia',
    store: 'reebelo-au',
    lang: 'en',
  },
  {
    img: imgNZ,
    title: 'New Zealand',
    store: 'reebelo-nz',
    lang: 'en',
  },
  {
    img: imgSG,
    title: 'Singapore',
    store: 'quista',
    lang: 'en',
  },
  {
    img: imgCA,
    title: 'Canada (English)',
    store: 'reebelo-ca',
    lang: 'en',
  },
  {
    img: imgCA,
    title: 'Canada (French)',
    store: 'reebelo-ca', // Same store as Canada (English) but with different lang
    lang: 'fr',
  },
];

export default function LangCountrySelecter(props: {
  isOpen: boolean;
  isOneTimePopup?: boolean;
  onClose: (data?: {
    store?: string;
    lang?: string;
    storeLink?: string;
  }) => void;
}) {
  const { isOpen, isOneTimePopup } = props;
  const router = useRouter();

  const getStoreLink = (
    storeInfo: (typeof ALL_STORES)[number],
    oneTimePopup?: boolean,
    activeCountry?: boolean,
  ) => {
    const protocol = process.env.NEXT_PUBLIC_STAGE === 'dev' ? 'http' : 'https';
    const queryParam = `?popupFor=${storeInfo.store}`;

    return `${protocol}://${REEBELO_DOMAINS[storeInfo.store as ReebeloStoreT]}${
      storeInfo.lang === 'fr' ? '/fr/' : ''
    }${oneTimePopup && !activeCountry ? queryParam : ''}`;
  };

  const isActiveCountry = (
    storeInfo: (typeof ALL_STORES)[number],
    currentStore: string,
  ) =>
    storeInfo.store === currentStore &&
    ((storeInfo.lang === 'en' && (!router.locale || router.locale === 'en')) ||
      (storeInfo.lang === 'fr' && router.locale === 'fr'));

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={() => props.onClose()}
      open={isOpen}
    >
      <div className="flex min-h-screen items-start justify-center p-4 text-center sm:items-center sm:p-0">
        <Dialog.Overlay className="fixed inset-0 bg-gray-700 opacity-50" />

        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="inline-block h-screen align-middle "
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="relative z-50 mx-auto inline-block w-full overflow-hidden rounded-xl bg-white shadow-lg sm:min-h-max sm:max-w-[520px]">
          <button
            onClick={() => props.onClose()}
            type="button"
            className="btn-close absolute right-3 top-3 h-8 w-8 text-red opacity-100 outline-none md:right-2 md:top-2"
            aria-label="Close"
          ></button>
          <div className="w-full">
            <h2 className="flex w-full items-center px-6 pb-8 pt-5 text-left text-base font-bold text-gray-700 sm:pb-5 sm:pt-7 sm:text-lg">
              <span>{t`Choose your country & language`}</span>
              <span className="relative top-[-2px] ml-4 hidden sm:inline-flex">
                <Image
                  src="https://cdn.shopify.com/s/files/1/0244/0799/8519/files/Globe.svg"
                  alt="Globe-icon"
                  width={30}
                  height={33}
                />
              </span>
            </h2>
            <div>
              <ul className="w-full">
                {ALL_STORES.map((storeInfo) => {
                  const activeCountry = isActiveCountry(
                    storeInfo,
                    settings.store,
                  );

                  const storeLink = getStoreLink(
                    storeInfo,
                    isOneTimePopup,
                    activeCountry,
                  );

                  return (
                    <li
                      key={storeInfo.title}
                      className="px-6 py-4 odd:bg-gray-100 sm:py-2.5"
                    >
                      <a
                        href={storeLink}
                        onClick={(e) => {
                          if (activeCountry) {
                            e.preventDefault();
                            props.onClose();
                          } else {
                            props.onClose({
                              store: storeInfo.store,
                              lang: storeInfo.lang,
                              storeLink,
                            });
                          }
                        }}
                        className="flex items-center justify-between"
                      >
                        <span className="inline-flex items-center">
                          <span className="flex h-[23px] w-[35px] sm:h-[30px] sm:w-[45px]">
                            <Image
                              src={storeInfo.img}
                              alt={storeInfo.title}
                              width={45}
                              height={30}
                            />
                          </span>
                          <span
                            className={`${
                              activeCountry ? `font-bold` : ``
                            } ml-4 text-sm text-gray-700`}
                          >
                            {storeInfo.title}{' '}
                          </span>
                        </span>
                        <span
                          className={`${
                            activeCountry
                              ? `rounded-full bg-gray-700 p-1.5 pb-1 text-xs font-bold text-white`
                              : `text-sm text-gray-700 hover:text-blue-500`
                          } uppercase`}
                        >
                          {storeInfo.lang}
                        </span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
